<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="70">
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="
        $vuetify.breakpoint.smAndDown
          ? (setDrawer(!drawer))
          : 
          (
              setDrawer(!drawer),
              value = !value
          )
      "
    >
      <v-icon v-if="value">mdi-menu</v-icon>

      <v-icon v-else>mdi-close</v-icon>
    </v-btn>
    <v-spacer />
    <!--
    <v-icon 
      @click="logInUser"
      class="mouse-pointer mx-1"
    >
    
      mdi-logout
    </v-icon>
    -->
  </v-app-bar>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "PagesCoreAppBar",

  data: () => ({
    value: false,
  }),

  computed: {
    ...mapGetters('UI',
      ["drawer"]
    ),
  },
  
  methods: {
    ...mapMutations('UI',
      { setDrawer: "SET_DRAWER" }
    ),

    async logInUser() {
  
      const currentHref = window.location.href

      if(currentHref.includes('pop.dooh.com') || currentHref.includes('popstage.deliverdooh.com') || currentHref.includes('pop-gallery')) {
        this.$router.push('pop-gallery')
      } else {
        this.$router.push({ name: 'Login' })
      }
    },
  },
};
</script>
