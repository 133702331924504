<template>
  <v-card style="padding: 10px; background: var(--v-sidebarColorLight-darken2); overflow: hidden; margin-bottom: 8px; margin-top: 0px">
    <v-row dense>
      <v-col style="min-width:0px"> 
        <v-select 
          v-model="routeRequest" 
          :items="routeRequests" 
          :item-text="getRouteRequestLabel"
          :item-value="item => item"
          label="Route Audience Query" 
          hide-details 
          outlined 
          dense
          @click.stop
          :menu-props="{
            offsetY: true
          }"
        >
          <!-- Selection slot -->
          <template v-slot:selection="{ item }">
            <div class="compact-content">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-row no-gutters>
                    <v-col cols="auto" class="d-flex align-center" style="height: 25px">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-chip small class="height-100" color="darkgrey" label v-on="on">
                            v{{ item.routeVersion }}
                          </v-chip>
                        </template>
                        <span>Route Version</span>
                      </v-tooltip>
                    </v-col>
                    <v-col style="min-width: 0; overflow: hidden;">
                      <div class="title-text" style="padding-left:5px;margin-top:3px;">
                        {{ item.description ? item.description : item.formula }}
                      </div>
                    </v-col>
                  </v-row>
                </template>
                <span>{{ item.demographicCustom || 'No custom query' }}</span>
              </v-tooltip>
            </div>
            </template>
  
          <!-- Item slot -->
          <template v-slot:item="{ item }">
            <div class="compact-content">
              <v-row no-gutters>
                <v-col cols="auto" class="d-flex align-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-chip small class="height-100" color="darkgrey" label v-on="on">
                        v{{ item.routeVersion }}
                      </v-chip>
                    </template>
                    <span>Route Version</span>
                  </v-tooltip>
                </v-col>
                <v-col class="pl-2 text-truncate">
                  <div class="title-text text-truncate" style="white-space: normal !important;">
                    {{ item.description ? item.description : item.formula }}
                  </div>
                  <div class="formula-container text-truncate">
                    <DemographicLexer :value="item.formula" display-only hideMessages class="compact-lexer" />
                  </div>
                </v-col>
              </v-row>
            </div>
          </template>
        </v-select>
      </v-col>
      <v-col v-if="$store.state.Permissions.routeTargetEdit" cols="auto">
        <v-menu 
          offset-y 
          :close-on-content-click="false"
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
              style="width:40px !important; height: 40px; "
              small
              dark 
              v-bind="attrs" 
              v-on="on">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-card class="menu-card">
            <v-row dense>
              <v-col>
                <v-row dense>
                  <!-- Route Versions Dropdown -->
                   <v-col cols="4">
                    <v-autocomplete
                      v-model="routeRequestForm.routeVersionId"
                      style="border-bottom-right-radius: 0px; border-top-right-radius: 0px;"
                      :items="routeVersions"
                      :item-text="(item) => 'v' + item.routeReleaseId + '.' + item.routeReleaseRevision + ' [' + item.routeAlgorithmVersion + ']'"
                      item-value="id"
                      label="Route Version"
                      hide-details
                      outlined
                      dense
                      autocomplete
                      :filter="customFilter"
                    />
                   </v-col>
                   <!-- Granularity Dropdown -->
                   <v-col cols="4">
                    <v-autocomplete
                      v-model="routeRequestForm.routeGranularityAudienceId"
                      style="border-radius: 0px;"
                      label="Granularity"
                      :items="granularityAudiences.data"
                      item-text="description"
                      item-value="id"
                      hide-details
                      outlined
                      dense
                      autocomplete
                      :filter="customFilter"
                    />
                   </v-col>
                   <!-- Demographics Dropdown -->
                   <v-col cols="4">
                    <v-autocomplete
                      v-model="routeRequestForm.routeDemographicId"
                      style="border-bottom-left-radius: 0px; border-top-left-radius: 0px;"
                      label="Demographic"
                      :items="demographics.data"
                      item-text="description"
                      item-value="id"
                      hide-details
                      outlined
                      dense
                      autocomplete
                      :filter="customFilter"
                    />
                   </v-col>
                  </v-row>
              </v-col>
            </v-row>
            <v-row dense class="mt-2">
              <v-col>
                <!-- Custom Query -->
                <v-text-field v-model="routeRequestForm.demographicCustom" label="Custom Query (optional)" hide-details
                  clearable outlined dense />
              </v-col>
              <v-col cols="auto">
                <v-btn @click="addCampaignRouteRequest()" color="primary"
                style="width:40px !important; height: 40px; "
                small>Add</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
    <v-progress-linear
    v-show="isLoading"
    style="position: absolute; bottom: 0; left: 0; width: 100%;"
    indeterminate
  ></v-progress-linear>
</v-card>
</template>

<script>
import RouteController from '@/services/controllers/Route'
import DemographicLexer from '@/components/_theme/demographicLexer/DemographicLexer.vue'

export default {
  name: 'AudienceStringSelector',
  components: {
      DemographicLexer
    },
  props: {
    campaignId: {
      type: Number,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    incomingRouteRequest: {
      type: Object,
      required: false
    }
  },

  data() {
    return {
      routeRequest: {},
      routeRequests: [],
      routeVersions: [],
      demographics: { data: [] },
      granularityAudiences: { data: [] },
      routeRequestForm: {
        routeVersionId: null,
        routeDemographicId: null,
        routeGranularityAudienceId: null,
        demographicCustom: '',
      },
    }
  },

  async mounted() {
    console.log("AudienceStringSelector mounted");
  },

  async created() {
    await this.getCampaignRouteRequests()
    await this.getDropdownValues()
  },

  methods: {
    getRouteVersionLabel(item) {
  let routeVersion = this.routeVersions.find(rv => rv.id === item.routeVersionId)
  if (!routeVersion) return 'v?'
  return `v${routeVersion.routeReleaseId}.${routeVersion.routeReleaseRevision}`
},
    customFilter(item, queryText, itemText) {
      const text = item.description || itemText;
      return text.toLowerCase().indexOf(queryText.toLowerCase()) > -1;
    },
    getRouteRequestLabel(routeRequestItem) {
      let routeRequest = this.routeRequests.find(rr => rr.id === routeRequestItem.id)
      if(routeRequest === undefined) {
        return
      }
      let routeVersion = this.routeVersions.find(rv => rv.id === routeRequest.routeVersionId)
      if(routeVersion === undefined) {
        return
      }
      let demographic = this.demographics.data.find(d => d.id === routeRequest.routeDemographicId)
      let demographicLabel = demographic ? demographic.description : ''
      let granularity = this.granularityAudiences.data.find(g => g.id === routeRequest.routeGranularityAudienceId)
      let granularityLabel = granularity ? granularity.description : ''
      let demographicCustomLabel = routeRequest.demographicCustom ? " : " + routeRequest.demographicCustom : ''
      return granularityLabel + ", " + demographicLabel + demographicCustomLabel
    },

    getDemographicFormula(routeRequestItem) {
      let routeRequest = this.routeRequests.find(rr => rr.id === routeRequestItem.id)
      if(routeRequest === undefined) {
        return
      }
      let routeVersion = this.routeVersions.find(rv => rv.id === routeRequest.routeVersionId)
      if(routeVersion === undefined) {
        return
      }
      let demographic = this.demographics.data.find(d => d.id === routeRequest.routeDemographicId)
      let demographicFormula = demographic ? demographic.expression : ''
      return demographicFormula
    },

    async getCampaignRouteRequests() {
      try {
        const res = await RouteController.getCampaignRouteRequests(this.campaignId)
        this.routeRequests = res.data.map(rr => rr.routeRequest)
        if(this.routeRequests.length > 0) {
          this.routeRequest = this.incomingRouteRequest && this.incomingRouteRequest.id != null ? this.incomingRouteRequest : this.routeRequests[0]
        }
        this.$emit('route-requests-updated', this.routeRequests)
      } catch (err) {
        console.log(err)
      }
    },

    async getDropdownValues() {
      try {
        this.demographics = await RouteController.getDemographics()
        this.granularityAudiences = await RouteController.getGranularityAudiences()
        const rvers = await RouteController.getRouteVersions()
        this.routeVersions = rvers.data
      } catch (err) {
        console.log(err)
      }
    },

    addCampaignRouteRequest() {
      let demographicCustom = this.routeRequestForm.demographicCustom == null ? '' : this.routeRequestForm.demographicCustom

      if(this.routeRequestForm.routeVersionId == null || this.routeRequestForm.routeDemographicId == null || this.routeRequestForm.routeGranularityAudienceId == null)
      {
        this.$root.$emit('snackbarError', 'Please select a Route Version, Demographic and Granularity Audience')
        return
      }
      
      let routeRequestObj = {
        campaignId: this.campaignId,
        routeVersionId: this.routeRequestForm.routeVersionId,
        routeDemographicId: this.routeRequestForm.routeDemographicId,
        routeGranularityAudienceId: this.routeRequestForm.routeGranularityAudienceId,
        demographicCustom: demographicCustom,
      }

      RouteController.addCampaignRouteRequest(routeRequestObj)
        .then((res) => {
          console.log("add route request response", res)
          this.routeRequests.push(res.data)
          this.$root.$emit('snackbarSuccess', 'Route Request Added')
          this.$emit('route-request-added', res.data)
          
          // Reset the form
          this.routeRequestForm = {
            routeVersionId: null,
            routeDemographicId: null,
            routeGranularityAudienceId: null,
            demographicCustom: '',
          }
        })
        .catch((error) => {
          console.log("add route request error message",error)
          this.$root.$emit('snackbarError', 'Error adding Route Request: ' + error.response.data.message)
        })
    },
  },

  watch: {
    incomingRouteRequest(val, oldVal) {
      if(val && oldVal && val.id > 0 && val.id != oldVal.id) {
        this.routeRequest = val
      }
    },
    routeRequest(val, oldVal) {
      if(val && oldVal && val.id > 0 && val.id != oldVal.id) {
        this.$emit('route-request-changed', val)
      }
    }
  }
}
</script>

<style scoped>
.compact-content {
width: 100%;
overflow: hidden;
}

.title-text {
  font-size: 1rem;
font-weight: 500;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
line-height: 20px;
}

.formula-container {
margin-top: -2px;
filter: grayscale(0.3);
font-size: 0.875rem;
color: rgba(255, 255, 255, 0.7);
}

.height-100 {
height: 100% !important;
width: 48px;
}

::v-deep .v-list-item {
padding: 4px 16px !important;
}

/* Add this new class */
::v-deep .v-select__selections {
max-height: 40px;
overflow: hidden;
}

/* Add this to ensure the row stays within bounds */
.v-row.no-gutters {
max-width: 100%;
margin: 0;
}

/* Ensure the column doesn't overflow */
.v-col {
  min-width: 0;
  overflow: hidden;
}

.menu-card {
margin: 0px;
margin-top: 5px;
width: 450px !important;
padding: 10px;
background: var(--v-sidebarColorLight-darken1);
overflow: hidden;
width: 100%;
}
</style>