// lexerWorkerService.js
import Worker from 'worker-loader!./demographicLexerWorker.js';

class LexerWorkerService {
  constructor() {
      if (LexerWorkerService.instance) {
          return LexerWorkerService.instance;
      }

      this.worker = null;
      this.callbacks = new Map();
      this.nextId = 0;
      this.referenceCount = 0;

      LexerWorkerService.instance = this;
  }

  initialize() {
      if (!this.worker) {
          this.worker = new Worker();
          this.worker.onmessage = (e) => {
              const { id, ...data } = e.data;
              const callback = this.callbacks.get(id);
              if (callback) {
                  callback(data);
                  this.callbacks.delete(id);
              }
          };
      }
      this.referenceCount++;
  }

  process(text, callback) {
      if (!this.worker) return;
      const id = this.nextId++;
      this.callbacks.set(id, callback);
      this.worker.postMessage({ id, text });
  }

  release() {
      this.referenceCount--;
      if (this.referenceCount <= 0) {
          this.destroy();
      }
  }

  destroy() {
      if (this.worker) {
          this.worker.terminate();
          this.worker = null;
          this.callbacks.clear();
          this.referenceCount = 0;
      }
  }
}

export default new LexerWorkerService();
