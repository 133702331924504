<template>
    <div>
        <!-- Burst Progress Bar -->
        <v-row class="mt-2">
            <v-col cols="12">
            <v-expansion-panels :value="shouldExpandByDefault">
              <v-expansion-panel>
                <!-- Header-->
                <v-expansion-panel-header :color="$vuetify.theme.dark ? '#363636' : '#d6d6d6'">
                  <div
                      class="d-flex justify-between align-center mouse-pointer font-weight-bold"
                      :style="{ width: `${100}%`, height: `${50}px` }"
                  >
                    <div class="ml-3 d-flex align-center" style="gap: 0.6em;"><div style="font-size: 1.2em;">{{ burstCopy.name.charAt(0).toUpperCase() + burstCopy.name.slice(1) }}</div> <div>-</div> <div style="font-size: 1.2em;">{{ burstCopy.deliveryType.name }}</div></div>
                        <div class="font-weight-light d-flex align-center" style="font-size: 0.8em;" v-if="burstCopy.startDate">
                            <!-- Dates -->
                            <v-tooltip top>
                                <template v-slot:activator="{ attrs, on }">
                                <span
                                    v-bind="attrs"
                                    v-on="on"
                                    class="ml-5"
                                >{{ burstCopy.startDate | moment}} - </span>
                                </template>
                              <span>Start Date</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ attrs, on }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                                class="ml-1"
                              > {{ burstCopy.endDate | moment}}</span>
                            </template>
                              <span>End Date</span>
                          </v-tooltip>
                        </div>

                        <MilestonesOneLiner :burst="burst"/>

                        <div class="ml-auto font-weight-light d-flex align-center">
                          <v-tooltip top>
                            <template v-slot:activator="{ attrs, on }">
                                <span
                                    v-bind="attrs"
                                    v-on="on"
                                    class="mr-10"
                                    v-if="!!burstCopy.workflowStatus && burstCopy.workflowStatus.id !== 0"
                                >
                                    <v-progress-circular
                                        :value="getProgress(burstCopy)"
                                        size="30"
                                        width="4"
                                        color="primary"
                                    />
                                </span>
                            </template>
                            <span v-if="!!burstCopy.workflowStatus && burstCopy.workflowStatus.id !== 0">{{ getProgress(burstCopy) }} / 100 %</span>
                          </v-tooltip>
                        </div> 
                      </div>
                </v-expansion-panel-header>
                <!-- Content-->
                <v-expansion-panel-content>
                    <MilestonesBar :burst="burst"/>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            </v-col>
        </v-row>
        <v-card style="height: 100%" class="mb-6">
            <!-- Burst title
            <v-row class="primary ma-0">
                <v-col cols="6">
                    <h3 style="color: white">
                        {{ burstCopy.name }} - {{ burstCopy.deliveryType.name }}
                        <span v-if="burst.startDate" :style="{ fontWeight: `300`, fontSize: `12px`, marginLeft: '10px' }">
                            {{ burstCopy.startDate | moment }} - {{ burstCopy.endDate | moment }}.
                        </span>
                    </h3>
                </v-col>
            </v-row> -->
            <!-- Burst details -->
            <v-card-text>
                <v-row>
                    <v-data-table 
                        :headers="headers"
                        :items="tableData"
                        :hide-default-footer="false"
                        :items-per-page="10" 
                        :expanded.sync="expanded"
                        :loading="!mapped"
                        show-expand
                        style="width: 100%"
                    >
                        <!-- Get the item.live header and add a tolltip to the text-->
                        <template v-slot:header.live="{ header }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">{{ header.text }}</span>
                                </template>
                                <span>Aggregate: Check, Cumulative: Uncheck</span>
                            </v-tooltip>
                        </template> 

                        <!-- Name + Logo -->
                        <template v-slot:name="{ item }">
                            <div v-if="item.logo !== null">
                                <v-img :src="item.logo" style="width: 60px; height: 50px"></v-img>
                            </div>
                            <div v-else style="max-width: 100px;">
                                {{ item.name }}
                            </div>
                        </template>

                        <!-- Formats -->
                        <template v-slot:item.format="{ item }">
                            <div style="max-width: 100px;">
                                {{ item.screen.length }}
                            </div>
                        </template>

                        <!-- kpi -->
                        <template v-slot:item.kpi="{ item }">
                            <div style="max-width: 150px;" class="d-flex justify-end ml-3" >
                                <!-- Select button with 3 values to choose from-->
                                <v-select
                                    :items="kpiList"
                                    v-model="item.kpi"
                                    item-value="name"
                                    label="KPI"
                                    outlined
                                    dense
                                    hide-details
                                    @change="changeKPI(item)"
                                >
                                </v-select>
                            </div>
                        </template>

                        <!-- Dates -->
                        <!-- TODO: change dates to display the earliest startDate present and the latest endDate present -->
                        <template v-slot:item.date="{ item }">
                            <div>
                                <small :class="hasAnalyticsEditPermission && type.includes('tracking') && !datesMatchBurst(item) ? 'datesMismatch' : ''" style="font-size: x-small;">
                                    <span v-if="getEarliestDate(item) && getLatestDate(item)">
                                        {{ getEarliestDate(item) | moment }} - {{ getLatestDate(item) | moment }}
                                    </span>
                                    <span v-else>
                                        <span v-if="type.includes('playout')">Burst Only</span>
                                        <span v-else>Playout Only</span>
                                    </span>
                                </small>
                            </div>
                        </template>

                        <!-- Booked -->
                        <template v-slot:item.booked="{ item }">
                            <div>
                                <small>{{ calculateBookedTotal(item) }}</small>
                            </div>
                        </template>

                        <!-- Delivered -->
                        <template v-slot:item.delivered="{ item }">
                            <div>
                                <small>{{ calculateDeliverTotal(item) }}</small>
                            </div>
                        </template>

                        <!-- Performance -->
                        <template v-slot:item.performance="{ item }">
                            <div>
                                <small>{{ calcPerformance(removeCommas(calculateBookedTotal(item)), removeCommas(calculateDeliverTotal(item))) }}</small>
                                <small>%</small>
                            </div>
                        </template>

                        <!-- Comments -->
                        <template v-slot:item.comment="{ item }">
                            <div v-if="hasValidCBS(item)">
                                <v-btn
                                    :color=" hasMoNotes(item) ? 'primary' : 'grey'" 
                                    id="no-background-hover" 
                                    icon
                                    @click="showCommentDialogAll(item)"
                                >
                                    <v-icon>mdi-comment</v-icon>
                                </v-btn>
                            </div>
                        </template>

                        <!-- Expansion-->
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headersExpanded.length" style="width: 100%" class="ma-0 pa-0">
                                <v-data-table
                                    :headers="headersExpanded"
                                    :items="item.screen"
                                    hide-default-footer
                                    :items-per-page="100"
                                    :loading="!mapped"
                                    style="width: 100%;"
                                >
                                    <!-- Name -->
                                    <template v-slot:item.name ="{ item }" >
                                        <div style="max-width: 100px;">{{ item.n }}</div>
                                    </template>
                                    <!-- Dates -->
                                    <template v-slot:item.date="{ item }">
                                        <v-menu
                                            v-model="item.datePickerOpen"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                            @input="openDatePicker(item)"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <small
                                                    style="font-size: x-small;"
                                                    :class="hasAnalyticsEditPermission && type.includes('tracking') && !datesMatch(item) ? 'datesMismatch' : ''"
                                                > <span v-if="(item.stats[getKPIIndex(item)].sd)&& (item.stats[getKPIIndex(item)].ed)"
                                                        v-bind="(hasAnalyticsEditPermission && type.includes('tracking')) ? attrs : ''"
                                                        v-on="(hasAnalyticsEditPermission && type.includes('tracking')) ? on : ''">
                                                    {{ item.stats[getKPIIndex(item)].sd | moment }} - {{ item.stats[getKPIIndex(item)].ed | moment }} 
                                                  </span>
                                                  <span v-else>
                                                    <span v-if="type.includes('playout')">Burst Only</span>
                                                    <span v-else>Playout Only</span>
                                                  </span>
                                                </small>
                                            </template>
                                            <v-date-picker
                                                v-model="datePicker"
                                                @change="updateTarget(item)"
                                                :allowed-dates="allowedDates(item)"
                                                :picker-date="displayDate"
                                                @update:picker-date="updatePickerDate"
                                                range
                                                no-title
                                                scrollable
                                            >
                                                <template v-slot:actions>
                                                    <v-btn color="primary" text @click="clearDate">Reset</v-btn>
                                                </template>
                                            </v-date-picker>
                                            <v-row style="flex-direction: column; padding: 5px; width: 10px;">
                                                <v-btn @click="resetDates(item)" class="my-2" color="warning" dark>Reset</v-btn>
                                            </v-row>
                                        </v-menu>
                                    </template>
                                    <!-- Booked -->
                                    <template v-slot:item.booked="{ item }">
                                        <small>
                                            <div class="editable" :contenteditable="hasAnalyticsEditPermission && type.includes('tracking')" @blur="(e) => updateTarget(item, e, 'booked')">
                                                <span>{{ addCommas(item.stats[getKPIIndex(item)].booked) }}</span>
                                            </div>
                                        </small>
                                    </template>

                                    <!-- Delivered / Actuals -->
                                    <template v-slot:item.delivered="{ item }">
                                        <small>
                                            <div :contenteditable="hasAnalyticsEditPermission && type.includes('tracking')" @blur="(e) => updateTarget(item, e, 'delivered')">
                                                <span>{{ addCommas(item.stats[getKPIIndex(item)].delivered) }}</span>
                                            </div>
                                        </small>
                                    </template>

                                    <!-- Performance -->
                                    <template v-slot:item.performance="{ item }">
                                        <div>
                                            <small>{{ calcPerformance(item.stats[getKPIIndex(item)].booked, item.stats[getKPIIndex(item)].delivered) }}</small>
                                            <small>%</small>
                                        </div>
                                    </template>
                                    <!-- Aggregate -->
                                    <template v-slot:item.live="{item}">
                                        <div class="d-flex justify-center" v-if="item.cbsid != 0">
                                            <v-checkbox
                                                :disabled="!hasAnalyticsEditPermission || !type.includes('tracking')"
                                                v-model="item.stats[getKPIIndex(item)].targetFullRange"
                                                color="primary"
                                                hide-details
                                                class="ma-0 pa-0"
                                                @change="updateTarget(item)"
                                            ></v-checkbox>
                                        </div>
                                    </template>
                                   <!-- Comments -->
                                    <template v-slot:item.comment="{ item }">
                                        <div v-if="item.cbsid != 0">
                                            <v-btn
                                                :color="hasFormatNotes(item) ? 'primary' : 'grey'" 
                                                id="no-background-hover" 
                                                icon
                                                @click="showCommentDialog(item)"
                                            >
                                                <v-icon>mdi-comment</v-icon>
                                            </v-btn>
                                        </div>
                                    </template>
                                    <!-- KPI -->
                                    <template v-slot:item.kpi="{item}">
                                    </template>
                                </v-data-table>
                            </td>
                        </template>
                    </v-data-table>
                </v-row>
            </v-card-text>
        </v-card>
        <!-- comment dialog -->
        <v-dialog 
            v-model="showCommDialog" 
            max-width="500px"
            v-if="selectedItem"
            @click:outside="closeDialog()"
        >
            <v-card>
                <v-card-title class="primary">
                    <span class="headline">Notes</span>
                </v-card-title>
                <!-- If itemSelect.notes.length > 0 , iterate through it and display the note in card-->
                <v-card-text>
                    <v-card v-if="selectedItem.note" class="
                        d-flex 
                        justify-center 
                        align-center 
                        flex-column 
                        text-center
                        mb-4
                        pa-4
                        border
                    ">
                        <v-row style="width: 100%;" class="
                            font-weight-bold
                            mb-2
                        ">
                            <v-col cols="4">
                                <small>Format: {{ findScreenNamebyCBSID(selectedItem.note.campaignBurstScreenId) }}</small>
                            </v-col>
                            <v-col cols="4">
                               <small>Mode: {{ selectedItem.note.dashboardMode }}</small>
                            </v-col>
                            <v-col cols="4">
                               <small>KPI: {{ selectedItem.note.dashboardKPI }}</small>
                            </v-col>
                        </v-row>
                        {{ selectedItem.note.note }}
                    </v-card>
                    <v-textarea
                        v-if="hasAnalyticsEditPermission"
                        v-model="comment"
                        label="Note"
                        outlined
                        dense
                        hide-details
                        rows="3"
                        class="mt-4"
                    ></v-textarea>
                </v-card-text>
                <v-card-actions>
                    <v-btn :disabled="!hasAnalyticsEditPermission" color="primary" text @click="addNote(selectedItem)">Save</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="closeDialog()">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Comment dialog -->
        <v-dialog 
            v-model="showCommDialogMO" 
            max-width="500px"
            @click:outside="closeDialog()"
        >
            <v-card>
                <v-card-title class="primary">
                    <span class="headline">Notes</span>
                </v-card-title>
                <!-- If itemSelect.notes.length > 0 , iterate through it and display the note in card-->
                <v-card-text>
                    <div v-if="selectedMoNotes.length > 0">
                        <v-card v-for="note in selectedMoNotes" :key="note.id"
                        class="
                            d-flex 
                            justify-center 
                            align-center 
                            flex-column 
                            text-center
                            mb-4
                            pa-4
                            border
                        ">
                            <v-row style="width: 100%;" class="
                            font-weight-bold
                            mb-2
                            ">
                                <v-col cols="4">
                                    <small>Format: {{ findScreenNamebyCBSID(note.campaignBurstScreenId) }}</small>
                                </v-col>
                                <v-col cols="4">
                                    <small>Mode: {{ note.dashboardMode }}</small>
                                </v-col>
                                <v-col cols="4">
                                    <small>KPI: {{ note.dashboardKPI }}</small>
                                </v-col>
                            </v-row>
                           <span>{{ note.note }}</span>
                        </v-card>
                    </div>
                    <div v-else>
                        No notes
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" text @click="closeDialog()">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// External libraries
import moment from 'moment'
// Controllers
import RouteController from '@/services/controllers/Route'
/** Components/Modals */
import IssueModal from '@/components/campaigns/modals/ProgressIssueModal'
import MilestonesOneLiner from '@/components/campaign/MilestonesOneLiner'
import MilestonesBar from '@/components/campaign/MilestonesBar'
import cloneDeep from 'lodash/cloneDeep'

export default {
    components: {
        IssueModal,
        MilestonesOneLiner,
        MilestonesBar
    },
    name: 'Report',
    props: {
        burst: {
            type: Object,
            required: true
        },
        campaign: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            default: 'report'
        },
        data: {
            type: Object,
            required: true
        },
        kpiList: {
            type: Array,
            required: true
        },
        kpiListObjects: {
            type: Object,
            required: true
        },
        arbSync: {
            type: Number,
            default: 0,
            required: true
        }
    },
    data() {
        return {
            headers: [
                { text: 'Media Owner', value: 'fn', width: '140px', align: 'left', sortable: false },
                { text: 'Formats', value: 'format', width: '100px', align: 'center', sortable: false },
                { text: 'Dates', value: 'date', width: '100px', align: 'center', sortable: false },
                { text: 'KPI', value: 'kpi', width: '200px', align: 'center', sortable: false },
                { text: 'Booked', value: 'booked', width: '100px', align: 'center', sortable: false },
                { text: 'Aggregate', value: 'live', width: '100px', align: 'center', sortable: false },
                { text: this.type.includes('verified') ? 'Actuals' : 'Delivered', value: 'delivered', width: '100px', align: 'center', sortable: false },
                { text: 'Performance %', value: 'performance', width: '100px', align: 'center', sortable: false },
                { text: 'Comments', value: 'comment', width: '100px', align: 'center', sortable: false },
                { text: '', value: 'data-table-expand', width: '10%', align: 'right', sortable: false }
            ],
            headersExpanded: [
                { text: '', value: 'placeholder', width: '0px', align: 'left', sortable: false },
                { text: '', value: 'name', width: '210px', align: 'left', sortable: false },
                { text: '', value: 'date', width: '100px', align: 'center', sortable: false },
                { text: '', value: 'kpi', width: '200px', align: 'center', sortable: false },
                { text: '', value: 'booked', width: '100px', align: 'center', sortable: false },
                { text: '', value: 'live', width: '100px', align: 'center', sortable: false },
                { text: '', value: 'delivered', width: '100px', align: 'center', sortable: false },
                { text: '', value: 'performance', width: '100px', align: 'center', sortable: false },
                { text: '', value: 'comment', width: '100px', align: 'center', sortable: false },
                { text: '', value: 'data-table-expand', width: '10%', align: 'right', sortable: false }
            ],
            expanded: [],
            burtCopy: {},
            showCommDialog: false,
            showCommDialogMO: false,
            selectedItem: null,
            comment: '',
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu: false,
            notesData: [],
            datePicker: null,
            displayDate: null,
            targetFullRange: false,
            selectedMoNotes: [],
            openIssueModal: false,
            mapped: false,
            tableData: [],
            stopwatch: 0,
            reportWorker: null
        } 
    },
    filters: {
        moment: function (date) {
            return moment(date).format('DD MMM YYYY')
        },
    },
    watch: {
        expanded: function(val, oldVal) {
            // Call the function after 100 ms so the DOM can update
            setTimeout(() => {
                this.addPasteEventListener()
            }, 100)
        },
        arbSync: async function(val, oldVal) {
            {
                let tab = this.$store.getters.getCampaignTab || this.$route.query.tab
                //if(val != oldVal && tab == this.type)
                {
                    this.mapStats()
                }
            }
        }
    },
    mounted() {
        let tab = this.$store.getters.getCampaignTab || this.$route.query.tab
        if(tab != this.type) return
        if(!this.mapped)
            this.mapStats()
    },
    beforeMount() {
        // Deep copy the burst prop
        this.burstCopy = JSON.parse(JSON.stringify(this.burst))
        this.$nextTick() 
        
    },
    computed: {
        shouldExpandByDefault() {
            return !!this.burst.workflowStatus && this.burst.workflowStatus.id !== 0 ? 0 : undefined;
        },
        addCommas() {
            return (number) => {
                // add stopwatch to calculate time it takes to run this function
                const num = parseFloat(number).toFixed(2);
                // Convert the number to a string
                const numberString = num.toString();
                // Split the number into integer and decimal parts
                const [integerPart, decimalPart] = numberString.split('.');
                // Add commas to the integer part
                const numberWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

                // Combine the integer part with the decimal part (if any)
                const result = decimalPart !== undefined ? numberWithCommas + '.' + decimalPart : numberWithCommas;
                // Return the result
                // add time taken to this.stopwatch
                return result;
            }
        },
        removeCommas(){
            return (number) => {
                return number.replace(/,/g, '')
            }
        },
        allowedDates() {
            return (format) => {
                return (val) => {
                    let cbsDates = this.getCampaignBurstScreenDates(format.cbsid)
                    let startDate = moment(cbsDates.startDate);
                    let endDate = moment(cbsDates.endDate);
                    let now = moment(val)
                    return now.isAfter(startDate) && now.isBefore(endDate) || now.isSame(startDate) || now.isSame(endDate);
                };
            };
        },
        getDisplayDate() {
            return (format) => {
                let cbsDates = this.getCampaignBurstScreenDates(format.cbsid)
                let startDate = format.stats[this.getKPIIndex(format)].sd || cbsDates.startDate;
                return startDate
            };
        },
        datesMatchBurst() {
            return (burst) => {
                // ensure all dates match within the burst
                let allMatch = true;
                burst.screen.forEach(cbs => {
                    if(!this.datesMatch(cbs))
                        allMatch = false
                })
                return allMatch
            };
        },
        datesMatch() {
            return (format) => {
                    if (format.stats === undefined) return false
                    let cbsDates = this.getCampaignBurstScreenDates(format.cbsid)
                    let startDate = moment(cbsDates.startDate);
                    let endDate = moment(cbsDates.endDate);
                    let kindex = this.getKPIIndex(format)
                    let targetStartDate = moment(format.stats[kindex].sd)
                    let targetEndDate = moment(format.stats[kindex].ed)
                    return (
                        targetStartDate.diff(startDate,'second') === 0 && targetEndDate.diff(endDate,'second') === 0
                    );
            };
        },
        getCampaignBurstScreenDates() {
            return (campaignBurstScreenId) => {
                let cbs = this.burstCopy.campaignBurstScreens.find(cbs => cbs.id === campaignBurstScreenId) // get CampaignBurstScreen
                if(cbs === undefined) 
                {
                    cbs = {} // this means data has come from playout, but is not in the campaignBurstScreens
                }
                let startDate = cbs.startDate || this.burstCopy.startDate || this.campaign.startDate;
                let endDate = cbs.endDate || this.burstCopy.endDate || this.campaign.endDate;
                return { startDate, endDate }
            }
        },
        getCampaignBurstDates() {
            return () => {
                let startDate = this.burst.startDate || this.campaign.startDate;
                let endDate = this.burst.endDate || this.campaign.endDate;
                return { startDate, endDate }
            }
        },
        getEarliestDate() {
            return (item) => {
                // Iterate through item.screen and append each stats[item.kpi].sd to an array
                let dates = []
                let arbSyncing = this.arbSync // this make it reactive
                item.screen.forEach(screen => {
                    if(screen.stats !== undefined && screen.stats[this.getKPIIndex(screen)].sd)
                        dates.push(screen.stats[this.getKPIIndex(screen)].sd)
                })
                if( dates.length === 0) return null
                // Find the earliest date in the dates array
                let earliestDate = dates.reduce((a, b) => {
                    return a < b ? a : b
                })
                return earliestDate
            };
        },
        getLatestDate(){
            return (item) => {
                // Iterate through item.screen and append each stats[item.kpi].sd to an array
                let dates = []
                let arbSyncing = this.arbSync // this make it reactive
                item.screen.forEach(screen => {
                    if(screen.stats !== undefined && screen.stats[this.getKPIIndex(screen)].ed)
                        dates.push(screen.stats[this.getKPIIndex(screen)].ed)
                })
                if( dates.length === 0) return null
                // Find the earliest date in the dates array
                let latestDate = dates.reduce((a, b) => {
                    return a > b ? a : b
                })
                return latestDate
            }
        },
        hasPlayoutVisibleOnTable() {
            return this.$store.state.Permissions.playoutVisibleOnTable
        },

        calcPerformance() {
            return (booked, delivered) => {
                let arbSyncing = this.arbSync // this make it reactive
                let perf = parseFloat((delivered)) / parseFloat((booked)) * 100;
                    // Check if perf is not in range of 1-100 or if it is NaN or Infinity
                    if (isNaN(perf) || !isFinite(perf) || perf < 1) return 0;
                    return perf.toFixed(2);
            };
        },
        calculateBookedTotal(){
            return (item) => {
                let total = 0
                let arbSyncing = this.arbSync // this make it reactive
                item.screen.forEach(screen => {
                    if(screen.stats !== undefined) {
                        total += parseFloat((screen.stats[this.getKPIIndex(screen)].booked))
                    }
                })
                return this.addCommas(total)
            }
        },
        calculateDeliverTotal(){
            return (item) => {
                let total = 0
                let arbSyncing = this.arbSync // this make it reactive
                item.screen.forEach(screen => {
                    if(screen.stats !== undefined) total += parseFloat((screen.stats[this.getKPIIndex(screen)].delivered))
                })
                return this.addCommas(total)
            }
        },
        getDelivered(){
            return (item) => {
                let total = 0
                if(item.stats !== undefined) total = parseFloat((item.stats[this.getKPIIndex(item)].delivered))
                return this.addCommas(total)
            }
        },
        getMoFormatActual(){
            return async (item) => {
                // FInd the media owner th
                return 0
            }
        },
        // Computed function to find index of this.selectedKPI in the kpiList
        getKPIIndex(){
            //return this.kpiList.findIndex(kpi => kpi === this.selectedKPI)
            return (item,extraVar) => {
                if(this.kpiList.findIndex(kpi => kpi === item.kpi) === -1) { console.log("kpi not found") ;return 0 }
                let retItem = this.kpiList.findIndex(kpi => kpi === item.kpi)
                return retItem
            }
        },
        findScreenNamebyCBSID(){
            return (cbsid) => {
                let screen = this.burstCopy.campaignBurstScreens.find(screen => screen.id === cbsid)
                return screen.screen.name
            }
        },
        // Function that gets a media owner object as a prop and return true or false depending if there are screens in mediaOwner.screen that have notes
        hasMoNotes() {
            return (mediaOwner) => {
            let hasNotes = false
            mediaOwner.screen.forEach(screen => {
                const isNote = this.hasFormatNotes(screen)
                if (isNote) {
                    hasNotes = true
                    return
                }

            })
            return hasNotes
            }
        },
        hasFormatNotes() {
            return (format) => {
                let hasNotes = false
                let isNotes = this.notesData.filter(note => note.campaignBurstScreenId === format.cbsid)
                // Filterthe notesData where 
                let mode = this.type.includes("verified") ? "auto" : ((this.type.includes("tracking")) ? "manual" : "playout");
                isNotes = isNotes.filter(note => note.dashboardMode === mode)
                // Check if the format.kpi is Impressions, Plays or Impacts/Impressions
                let kpiShortname = this.getKpiShortNameFromName(format.kpi)
                const isNote = isNotes.find(note => note.dashboardKPI === kpiShortname)
                if (isNote) {
                    hasNotes = true
                    if(isNote.note === '') return hasNotes = false
                }
                return hasNotes
            }
        },
        // Function to check is user has analyticsEdit permission
       hasAnalyticsEditPermission() {
        return this.$store.state.Permissions.analyticsEdit
      },
    },
    async created(){
        // Get the dashboardTargets for Tracking & Verified
        if(this.type.includes('tracking') || this.type.includes('verified') || this.type.includes('playout'))
        {
            //await this.mapDashboardTargets()
        }

        this.getMoFormatActual()

        
        await RouteController.getDashboardNotes(this.burst.id).then(response => {
            this.notesData = [...response.data]
        })
    },
    beforeDestroy() {
        if(this.reportWorker)
            this.reportWorker.terminate();
        // Remove the event listener when the component is destroyed
        // remove event listeners 
        document.removeEventListener('paste', this.handlePaste)
        // garbage collection
        this.notesData = undefined
        this.burstCopy = undefined
    },
    methods: {
        mapStats()
        {
            if(!this.reportWorker)
            {
                this.reportWorker = new Worker('/scripts/workers/ReportWorker.js?v1.0.0'); // caching buster
                this.reportWorker.onmessage = (event) => {
                    this.tableData = event.data;
                    this.mapped = true;
                    this.$root.$emit('loadNextBurst', "reports")
                };
            }

            if(!this.data.mediaOwners) return
            let data = {
                type: this.type,
                dashboardTargets: this.data,
                kpiList: this.kpiList,
                arbSync: this.arbSync,
                burst: this.burstCopy,
                kpiListObjects: this.kpiListObjects,
                tableData: this.tableData,
                hasPlayoutVisibleOnTable: this.hasPlayoutVisibleOnTable
            }
            this.reportWorker.postMessage(data)
        },

        hasValidCBS(item) {
            let hasNotes = item.screen.find(screen => screen.cbsid !== 0) !== undefined
            return hasNotes
        },

        openDatePicker(format) {
            format.datePickerOpen = true;
            this.displayDate = this.getDisplayDate(format);
            let cbsDates = this.getCampaignBurstScreenDates(format.cbsid)
            this.datePicker = [format.stats[this.getKPIIndex(format)].sd || cbsDates.startDate, format.stats[this.getKPIIndex(format)].ed || cbsDates.endDate];
        },

        updatePickerDate(newDate) {
        // This method updates the initialDate when the user changes the month
            this.displayDate = newDate;
        },
        getProgress(burst){
            let totalTasks = 0
            let completedTasks = 0
            if(burst){
                if(burst.workflowStatus){
                if(burst.workflowStatus.categories.length > 0){
                    burst.workflowStatus.categories.forEach(category => {
                    category.dIs.forEach(dIssue => {
                        dIssue.tasks.forEach(task => {
                        totalTasks++
                        if(task.s === 1)
                            completedTasks++
                        })
                    })
                    })
                }
                }
            }
            if(totalTasks === 0) return 0
            else return Math.round((completedTasks / totalTasks) * 100)
        },
        closeDialog() {
            this.showCommDialog = false
            this.showCommDialogMO = false
            this.selectedItem = null
            this.selectedMoNotes = []
            this.comment = ''
            this.$nextTick()
        },
        addPasteEventListener() {
            const editableElements = document.querySelectorAll('[contenteditable]');
            editableElements.forEach((element) => {
                element.addEventListener('paste', this.handlePaste);
            });
        },
        handlePaste(event) {
            event.preventDefault();
            // clear the innerText of the element . editable
            event.target.innerText = '';
            let text = event.clipboardData.getData('text/plain')
            document.execCommand('insertText', false, text)
        },
        async resetDates(format, e, type) {
            let cbsDates = this.getCampaignBurstScreenDates(format.cbsid)
            // set the datepicker to the allowed dates
            this.datePicker = [moment(cbsDates.startDate).format('YYYY-MM-DD'), moment(cbsDates.endDate).format('YYYY-MM-DD')]
            await this.updateTarget(format)
        },

        async updateTarget(item, e, type) {
            let campaignBurstScreens = this.data.mediaOwners.map(x => x.campaignBurstScreens).flat();
            let target = campaignBurstScreens.find(target => target.campaignBurstScreen.id === item.cbsid);
            let floatNumber = 0;

            // Format input text if exists
            if (e) {
                e.preventDefault();
                let value = e.target.innerText.replace('\n', '');
                value = value === '' || value === 'NaN' ? '0' : value;
                value = this.removeCommas(value);
                floatNumber = Number(value);
                //value = this.addCommas(value);

                // Update Vue data instead of DOM
                if (type === 'booked') {
                    this.$set(item, 'booked', value);
                    this.$set(item.stats[this.getKPIIndex(item)], 'booked', value);
                } else if (type === 'delivered') {
                    this.$set(item, 'delivered', value);
                    this.$set(item.stats[this.getKPIIndex(item)], 'delivered', value);
                }
            }

            // switcheroo dates if the start date is after the end date
            let startDate = this.datePicker ? this.datePicker[0] : item.stats[this.getKPIIndex(item)].sd;
            let endDate = this.datePicker ? this.datePicker[1] : item.stats[this.getKPIIndex(item)].ed;
            if (moment(startDate).isAfter(endDate)) {
                let temp = startDate;
                startDate = endDate;
                endDate = temp;
            }

            const newScreen = {
                campaignBurstScreenId: item.cbsid,
                mode: 'manual',
                routeRequestId: 0, // "manual"
                target: type === 'booked' ? floatNumber : (item.stats[this.getKPIIndex(item)].booked),
                actual: type === 'delivered' ? floatNumber : (item.stats[this.getKPIIndex(item)].delivered),
                targetFullRange: item.stats[this.getKPIIndex(item)].targetFullRange,
                startDate: startDate,
                endDate: endDate
            };

            // Set the type of the newScreen object
            let kpiShortname = this.getKpiShortNameFromName(item.kpi);
            newScreen.type = kpiShortname;

            // Update the dashboardTargets
            try {
                const response = await RouteController.updateDashboardTargets(newScreen);
                if (response.data) {
                    // Update the stats array using Vue's reactivity system
                    //this.$set(item, 'stats', response.data.stats);

                    // get the modeId
                    let modeId = this.getModeIdFromShortName('manual');

                    // Emit event to reload stat data
                    console.log("ModeID: ", modeId)
                    this.$root.$emit('reloadStatData', response.data, this.burst.id, modeId);

                    // Display snackbar depending on the type of the target changed
                    let message;
                    if (type === 'booked') {
                        message = 'Booked updated successfully';
                    } else if (type === 'delivered') {
                        message = 'Delivered updated successfully';
                    } else if (this.datePicker) {
                        message = 'Dates updated successfully';
                    } else {
                        message = 'Full range ' + (item.stats[this.getKPIIndex(item)].targetFullRange ? 'enabled' : 'disabled') + ' successfully';
                    }
                    this.$root.$emit('snackbarSuccess', message);
                }
            } catch (error) {
                console.error('Error updating dashboard targets:', error);
                this.$root.$emit('snackbarError', 'Failed to update target');
            }
            finally {
                // Reset the datepicker
                this.datePicker = null;
                item.datePickerOpen = false;
            }
        },
        async changeKPI(item, type){
            // find index of item in this.getMediaOwnersList
                let index = this.tableData.findIndex(mo => mo.id === item.id)
                    this.tableData[index].screen.forEach(screen => {
                        screen.kpi = item.kpi
                        let expandCopy = [...this.expanded]
                        this.expanded = []
                        this.$nextTick()
                        this.expanded = [...expandCopy]
                        this.$nextTick()
                })
        },

        getKpiIdFromName(kpiName) {
            let o = this.kpiListObjects.KPIs.find(kpi => kpi.name === kpiName)
            if(o) return o.id
        },

        getKpiIdFromShortName(shortName) {
            let o = this.kpiListObjects.KPIs.find(kpi => kpi.shortName === shortName)
            if(o) return o.id
        },

        getKpiShortNameFromName(kpiName) {
            let o = this.kpiListObjects.KPIs.find(kpi => kpi.name === kpiName)
            if(o) return o.shortName
        },

        getDesignationIdFromShortName(shortName) {
            let o = this.kpiListObjects.DESIGNATIONs.find(designation => designation.shortName === shortName)
            if(o) return o.id
        },

        getModeIdFromShortName(shortName) {
            let o = this.kpiListObjects.MODEs.find(mode => mode.shortName === shortName)
            if(o) return o.id
        },

        getKpiShortNameFromId(id) {
            let o = this.kpiListObjects.KPIs.find(kpi => kpi.id === id)
            if(o) return o.shortName
        },
        
        async addNote(item) {
            try {

                let kpiShortname = this.getKpiShortNameFromName(item.kpi)
                const obj = {
                    campaignBurstScreenId: this.selectedItem.cbsid,
                    mode: this.type.includes("verified") ? "auto" : ((this.type.includes("tracking")) ? "manual" : "playout"),
                    note: this.comment,
                    type: kpiShortname,
                };

                const res = await RouteController.setDashboardNotes(obj);

                const formatObj = {
                    campaignBurstScreenId: obj.campaignBurstScreenId,
                    dashboardKPI: obj.type,
                    note: obj.note,
                    dashboardMode: obj.mode,
                };

                let index = this.notesData.findIndex(note => note.campaignBurstScreenId === obj.campaignBurstScreenId && note.dashboardKPI === obj.type);

                if (index !== -1) {
                    // Update existing note or replace it
                    this.$set(this.notesData, index, formatObj);
                } else {
                    // Push new note if not found
                    this.notesData.push(formatObj);
                }

                // Update selectedItem and item notes using Vue.set for reactivity
                this.$set(this.selectedItem, 'note', formatObj);
                this.$set(item, 'note', formatObj);

                this.$root.$emit('snackbarSuccess', "Note added successfully");
            } catch (err) {
                console.log(err);
            } finally {
                this.message = '';
                this.showCommDialog = false;
                // Trigger reactivity by forcing the component to re-render
                this.$forceUpdate();
            }
        },

        showCommentDialog(item){
            // Data pre-processing
            this.selectedItem = item
            // check if selectedItem has one or more notes, if so, assign to this.selectedItem.notes array
            let notes = this.notesData.filter(note => note.campaignBurstScreenId === item.cbsid)
            // If current tab === 2 (verified) then filter notes by mode = auto
            let mode = this.type.includes("verified") ? "auto" : ((this.type.includes("tracking")) ? "manual" : "playout");
            notes = notes.filter(note => note.dashboardMode === mode)
            // Find the note that has type = item.kpi
            let kpiShortname = this.getKpiShortNameFromName(item.kpi)
            let note = notes.find(note => note.dashboardKPI === kpiShortname)
            if(note) this.selectedItem.note = note
            else this.selectedItem.note = null
            if(note) this.comment = note.note
            // Modal control
            this.showCommDialog = true
            this.$nextTick()
        },
        showCommentDialogAll(mediaOwner){
            this.showCommDialogMO = true
            // Get a list of notes that have the same campaignBurstScreenId as the mediaOwner.screen.cbsid (where screen is an array)
            let notes = this.notesData.filter(note => mediaOwner.screen.find(screen => screen.cbsid === note.campaignBurstScreenId))
            // If current tab === 2 (verified) then filter notes by mode = auto7
            let mode = this.type.includes("verified") ? "auto" : ((this.type.includes("tracking")) ? "manual" : "playout");
            notes = notes.filter(note => note.dashboardMode === mode)
            // Filter notes by kpi
            let kpi = mediaOwner.kpi;
            let kpiShortname = this.getKpiShortNameFromName(kpi)
            notes = notes.filter(note => note.dashboardKPI === kpiShortname)
            this.selectedMoNotes = notes
        },
    }
}
</script>
<style scoped>
*::v-deep .v-data-table__expanded .v-data-table-header th {
  height: 0px !important;
}
*::v-deep td .no-margin {
  padding: 0 !important;
}
</style>
<style lang="scss">
#no-background-hover {
   background-color: transparent !important;
}
</style>
<style scoped>
.v-card {
    margin-top:0;
    margin-bottom:0;
}
.together{
    min-width: 0
  }
.v-expansion-panel-header {
    padding: 0px 24px 0px 0px;
}
.datesMismatch {
    color: #ff9029;
}
</style>